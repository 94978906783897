import React from "react"

const outsourcing = {
  title: "nearshore outsourcing",
  desc:
    "Nearshore app development is becoming an increasingly viable and useful option for outsourcing. With a world that has embraced the digital era, it’s easy to see why there’s been a subsequent rise in nearshore outsourcing popularity. The fact that you can use Nearshore development teams to build all sorts of software at great rates makes it the preferred option for many companies. When considering Onshoring and Offshoring benefits and Disadvantages, Nearshoring offers benefits combine the best of both worlds:",
  benefits: [
    {
      title: "Time zone",
      desc:
        "Nearshore outsourcing helps eliminate communications obstacles due to different time zones. Argentina is located in UTC-3, meaning we can easily adapt and share most of our working day with your team, whether you are based on the east or the west coast.",
      icon: require("../assets/images/icon-benefits-1.svg"),
    },
    {
      title: "Quality / Cost Balance",
      desc:
        "We provide a highly educated workforce that can be employed at half (or lower) costs. This way you would spend much less for the same quality offered by an in-house dev team.",
      icon: require("../assets/images/icon-benefits-2.svg"),
    },
    {
      title: "High Quality",
      desc:
        "Argentina universities are recognised around the world for their highly skilled graduates. Our thorough recruiting process focuses on hiring only the best candidates.",
      icon: require("../assets/images/icon-benefits-3.svg"),
    },
    {
      title: "Culturally Aligned",
      desc:
        "We share a similar mindset and lifestyle with American people. This is key in understanding your project requirements and it allows us to share our point of view about your app features. We can see your projects both as developers as well as potential users because of our cultural similarity.",
      icon: require("../assets/images/icon-benefits-4.svg"),
    },
    {
      title: "Tech Skills",
      desc:
        "Coursera’s 2019 Global Skills Index ranks Argentina as a leader in cutting-edge technical skills. We have a large, skilled and bilingual pool of software engineers with the know-how to execute different kind of projects in an agile way, following strict methodologies to reach the highest quality standards.",
      icon: require("../assets/images/icon-benefits-5.svg"),
    },
    {
      title: "English Proficiency",
      desc:
        "Argentina has the highest English level in Latin America, and currently occupies the 15th place in the global ranking. Multilingual skills is a key factor for foreign companies to work with us.",
      icon: require("../assets/images/icon-benefits-6.svg"),
    },
  ],
}

const Outsourcing = () => (
  <section className="section-outsourcing">
    <div className="container">
      <h2 className="section-title">&lt; {outsourcing.title} &gt;</h2>
      <p className="section-desc">{outsourcing.desc}</p>
      <div className="holder-outsourcing">
        <ul className="holder-benefits-list">
          {outsourcing.benefits.map((item, i) => {
            return (
              <li className="benefits-list" key={i}>
                <div className="benefits-list-info">
                  <span className="benefits-list-no">{i + 1}</span>
                  <p className="title">{item.title}</p>
                  <p className="desc">{item.desc}</p>
                  <div className="holder-image">
                    <img
                      src={item.icon}
                      alt={`Benefit Icon ${item.title}`}
                      className="icon"
                    />
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  </section>
)

export default Outsourcing
