import React, { useState } from "react"
import { Form } from "react-bootstrap"

const contactCountry = [
  {
    icon: require("../assets/images/icon-country.svg"),
    country: "Uruguay",
    city: "MONTEVIDEO",
  },
  {
    icon: require("../assets/images/icon-country.svg"),
    country: "Argentina",
    city: "Buenos Aires",
  },
  {
    icon: require("../assets/images/icon-country.svg"),
    country: "Usa",
    city: "Los Angeles, California",
  },
]

const Contact = () => {
  const [message, handleUpdateMessage] = useState({
    fullname: "",
    email: "",
    bodyMessage: "",
  })

  const handleUpdateState = e => {
    handleUpdateMessage({
      ...message,
      [e.target.name]: e.target.value,
    })
  }

  const { fullname, email, bodyMessage } = message

  const handleSubmitMessage = e => {
    e.preventDefault()
    alert("Antes")

    handleUpdateMessage({
      fullname: "",
      email: "",
      bodyMessage: "",
    })
  }

  return (
    <>
      <section className="section-contact banner" id="contact">
        <div className="container">
          <div className="holder-contact-banner">
            <h3 className="banner-title">&lt; We play in your team &gt;</h3>
          </div>
        </div>
      </section>

      <section className="section-contact">
        <div className="container">
          <h2 className="section-title">&lt; Contact Us &gt;</h2>
          <div className="holder-contact">
            <div className="holder-form">
              <Form
                className="holder-form-group"
                // onSubmit={handleSubmitMessage}
                action="https://getform.io/f/5cc22299-bf38-4f8d-a09a-130737797a0f"
                method="POST"
              >
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    type="text"
                    name="fullname"
                    required="required"
                    onChange={handleUpdateState}
                    value={fullname}
                    placeholder=""
                  />
                  <Form.Label>&lt;Name&gt;</Form.Label>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    name="email"
                    required="required"
                    onChange={handleUpdateState}
                    value={email}
                    placeholder=""
                  />
                  <Form.Label>&lt;Email address&gt;</Form.Label>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    className="holder-form-textArea"
                    as="textarea"
                    rows="1"
                    required="required"
                    name="bodyMessage"
                    onChange={handleUpdateState}
                    value={bodyMessage}
                  />
                  <Form.Label>&lt;Type your message here&gt;</Form.Label>
                </Form.Group>

                <div className="holder-button">
                  <button className="button button-submit" type="submit">
                    &lt; Send &gt;
                  </button>
                </div>
              </Form>
            </div>
            <div className="holder-form-image">
              {/* <img src={FormImage} className="form-image" alt="Contact Form"/> */}
            </div>
          </div>
          <div className="holder-contact-country">
            <ul className="contact-country-list">
              {contactCountry.map((item, i) => {
                return (
                  <li className="country-list" key={i}>
                    <div className="holder-icon">
                      <img
                        src={item.icon}
                        alt={`Benefit ${item.country}`}
                        className="icon"
                      />
                    </div>
                    <p className="country">&lt; {item.country} &gt;</p>
                    <p className="city">{item.city}</p>
                  </li>
                )
              })}
            </ul>
          </div>

          <p className="contact-email">INFO@OFARIS.COM</p>
        </div>
      </section>
    </>
  )
}

export default Contact
