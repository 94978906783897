import React from "react"
import ChooseIconSafe from "../assets/images/choose-icon-1.png"
import ChooseIconGuarantee from "../assets/images/choose-icon-2.png"
import ChooseIconSupport from "../assets/images/choose-icon-3.png"

const whyUs = [
  {
    image: ChooseIconGuarantee,
    title: "Guaranteed quality",
    desc:
      "We build high-quality software that fully complies with your specific requirements and expectations, empowering users to perform tasks quickly and effortlessly, on any device, at any time.",
  },
  {
    image: ChooseIconSafe,
    title: "Qualified professionals",
    desc:
      "We have a talented and seasoned team of engineers and leaders with expertise in a wide range of technologies. Work with our professionals on a trial basis, ensuring you hire the right people for the job.",
  },
  {
    image: ChooseIconSupport,
    title: "Partnership",
    desc:
      "We engage each project with the highest commitment. Our relationship with clients goes beyond Commercial. It's about frequently communicating, building trust, and pursuing success together.",
  },
]

const ChooseUs = () => (
  <section className="section-why-choose">
    <div className="container">
      <h2 className="section-title">&lt; Why Choose Us &gt;</h2>
      <div className="holder-why-choose">
        {whyUs.map((item, i) => {
          return (
            <div className="holder-details" key={i}>
              <div className="holder-logo">
                <img className="logo" src={item.image} alt="Choose us logo" />
              </div>
              <p className="title">{item.title}</p>
              <p className="desc">{item.desc}</p>
            </div>
          )
        })}
      </div>
    </div>
  </section>
)

export default ChooseUs
